// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts'; 
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/brands';

//Dropzone
@import "~dropzone/src/dropzone";

.card {
	&.h-100 {
		padding-bottom: 2.5rem;
		.card-footer {
                    position: absolute;
		    bottom: 0;
		    left: 0;
		    right: 0;
		}
	}
}
